.footer {
    display: flex;
    justify-content: center;
    gap: 80px;
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
}

.footer > div > img {
    z-index: 1000;
    position: relative;
    max-height: 60px;
}
