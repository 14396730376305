@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
    --header-height: 3rem;
    --font-semi: 600;
    --hue-color: 224;
    --first-color: hsl(var(--hue-color), 89%, 60%);
    --second-color: hsl(var(--hue-color), 56%, 12%);
    --body-font: 'Poppins', sans-serif;
    --big-font-size: 2rem;
    --h2-font-size: 1.25rem;
    --normal-font-size: 0.938rem;
    --smaller-font-size: 0.75rem;
    --mb-2: 1rem;
    --mb-4: 2rem;
    --mb-5: 2.5rem;
    --mb-6: 3rem;
    --z-back: -10;
    --z-fixed: 100;
}

@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3.5rem;
        --h2-font-size: 2rem;
        --normal-font-size: 1rem;
        --smaller-font-size: 0.875rem;
    }
}

*,
::before,
::after {
    box-sizing: border-box;
}

body {
    font-family: 'source-sans-pro', sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    padding: 0;
}

input {
    border: none;
    border-bottom: 1px solid black;
    padding-top: 10px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.logoDesktop {
    max-height: 60px;
}

.bdGrid {
    max-width: 1300px;
    display: grid;
    margin-left: var(--mb-2);
    margin-right: var(--mb-2);
}

.menu {
    display: flex;
    align-items: center;
}

.lHeader {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000000000000000000;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(146, 161, 176, 0.15);
    position: relative;
}

.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-semi);
}

#search {
    width: 42vw;
    background-color: #f0f3f5;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    height: auto;
}

#search:focus-visible {
    outline: none;
}

#nav__menu {
    display: flex;
    align-items: center;
}

.searchingMobile {
    display: none;
    border-radius: 4px;
}

.searchingDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f3f5;
    padding-left: 12px;
    border-radius: 4px;
    margin-left: 20px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 36px;
}

.buttons > div:first-child > svg {
    position: relative;
    z-index: 1000000000000;
}

.buttons > div:first-child {
    cursor: pointer;
    margin-top: 8px;
}

.searchImage {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.friends {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
}

.paragraph {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    color: #8c8c8c;
    font-size: 20px;
    font-style: italic;
    margin-bottom: 5px;
}

.follow {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-top: 30px;
}

.socialMediaContainer {
    max-width: 1493px;
    margin-left: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 100%;
    display: flex;
    gap: 17px;
    align-items: center;
}

.socialMediaContainer > div > img {
    height: 35px;
}

@media screen and (max-width: 7000px) {
    .nav__menu {
        position: fixed;
        top: var(--header-height);
        margin-top: 10px;
        right: -100%;
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-color: white;
        transition: 0.5s;
        text-align: center;
        z-index: 1000;
        overflow: auto;
    }

    .searchingDesktop {
        display: flex;
    }

    .searchingMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f3f5;
        padding-left: 12px;
        width: 233px;
        margin-left: 49px;
        border: 1px solid #a3afb5;
        border-radius: 2px;
    }

    .menu {
        margin-bottom: 20px;
        display: block;
    }

    .menu > div {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .searchingMobile {
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
    .follow {
        text-align: center;
    }

    .friends {
        text-align: center;
    }

    .paragraph {
        text-align: center;
    }

    .socialMediaContainer {
        display: flex;
        justify-content: center;
    }
}

.nav__item {
    margin-bottom: var(--mb-4);
}

.nav__link {
    position: relative;
    color: black;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-size: 32px;
}

.activeLink {
    // border-bottom: 1px solid black;
    color: #a3afb5 !important;
}

.active .nav__link:hover {
    position: relative;
}

.nav__logo {
    color: var(--second-color);
    z-index: 1000000000;
    position: relative;
}

.nav__toggle {
    color: #a3afb5;
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 8px;
}

.hamburgerIcon,
.closeIcon {
    width: 24px;
    height: 24px;
}

.hamburgerIcon path,
.closeIcon path {
    stroke: #a3afb5;
    stroke-width: 2;
}

.searchIcon {
    display: none;
}

@media screen and (min-width: 768px) {
    body {
        margin: 0;
    }
    .nav {
        height: calc(var(--header-height) + 1rem);
    }
    .nav__list {
        display: flex;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 18px;
        font-size: 25px;
        margin-left: 160px;
    }
    .nav__item {
        margin-left: var(--mb-6);
        margin-bottom: 0;
    }

    .nav__item > a {
        text-transform: uppercase;
    }

    .nav__toggle {
        display: block;
    }
    .nav__link {
        color: black;
    }
}

@media screen and (min-width: 992px) {
    .bdGrid {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 880px) {
    .lHeader {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.show {
    right: 0;
}

.searchMenu {
    top: 48px;
    z-index: 10000000000000000;
    opacity: 0.99;
}

nav > div:first-child > a > img {
    z-index: 1000000000;
    position: relative;
}

.logoMobile {
    display: none;
}

.newsletter {
    margin-left: 3rem;
}

@media (max-width: 768px) {
    .searchMenu > div {
        margin-top: 50px;
    }

    .searchMenu > div > div:first-child {
        padding-bottom: 20px;
    }

    .logoDesktop {
        display: none;
    }

    .logoMobile {
        width: 70px;
        // height: 60px;
        display: block;
    }

    .newsletter {
        margin-left: 0;
    }
}

.sticky {
    position: sticky;
}

.yt {
    height: 37px;
}
