.container {
    max-width: 1540px;
    margin: 0 auto;
}

@media (max-width: 1180px) {
    .container {
        max-width: 940px;
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 440px;
    }
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

@media (max-width: 768px) {
    .mobile {
        display: flex;
        justify-content: end;
    }

    .desktop {
        display: none;
    }
}
