.title {
    font-size: 20px;
    color: black;
    margin-bottom: 25px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 700;
    font-style: normal;
}

.name {
    font-size: 20px;
    color: #a5b1b7;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 6px;
    margin-top: 0px;
}

.name {
    font-size: 20px;
    color: #a5b1b7;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 6px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
}
