.masonryGrid {
    columns: 3;
    gap: 3px;
    background-color: white;
    margin-top: -85px;
    position: relative;
    padding: 3px;
}

.masonryGrid > a > img {
    width: 100%;
}

.masonryGrid > a > video {
    width: 100%;
}

@media (max-width: 576px) {
    .masonryGrid {
        columns: 2;
        margin-top: -32px;
    }
}

@media (max-width: 767px) {
    .masonryGrid {
        margin-top: -10px;
    }
}

@media (max-width: 489px) {
    .masonryGrid {
        margin-top: -32px;
    }
}

@media (max-width: 450px) {
    .masonryGrid {
        margin-top: -32px;
    }
}

@media (max-width: 437px) {
    .masonryGrid {
        margin-top: -32px;
    }
}
