.vjs-big-play-button {
    z-index: 999;
}

.vjs-control-bar {
    z-index: 999;
}

.vjs-control-bar {
    opacity: 1 !important;
    visibility: visible !important;
    transition: none !important;
    pointer-events: auto !important;
}