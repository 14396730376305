.heading {
    font-size: 28px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin: 0px;
}

.paragraph {
    font-size: 20px;
    color: #5c6767;
    margin-bottom: 20px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 3px;
}

.paragraph2 {
    font-size: 15px;
    color: #5c6767;
    margin-bottom: 0px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.button {
    background-color: white;
    margin-bottom: 6px;
    padding-bottom: 6px;
}

.button > button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px 18px;
    padding-left: 0px;
    color: #5c6767;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.img {
    height: 150px;
    width: 150px;
    margin-right: 40px;
    margin-top: 10px;
}
.img5 {
    height: 150px;
    width: 150px;
    margin-right: 40px;
    margin-top: 10px;
    border-radius: 83px;
}

.text {
    max-width: 972px;
}

.container {
    display: flex;
    justify-content: start;
    margin-top: 30px;
    margin-left: 6vw;
    max-width: 1690px;
}

.containerImage {
    display: flex;
    justify-content: start;
    margin-top: 30px;
    margin-left: 6vw;
    max-width: 1690px;
}

.cities > a {
    color: #a5b1b7;
}

.cities {
    font-size: 20px;
    color: #a5b1b7;
    margin-bottom: 100px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.photoGrid {
    max-width: 1690px;
    max-height: 800px;
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 540px) {
    .container {
        margin-left: 0px;
        margin-top: 0px;
    }
    .containerImage {
        margin-left: 0px;
    }
    .img {
        height: 150px;
        width: 150px;
        margin-right: 10px;
        margin-top: 10px;
    }
    .heading {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 20px 20px 20px 20px;
    }
    .containerImage {
        flex-direction: row;
        padding: 20px 20px 20px 20px;
    }
}
