.masonryGrid {
    columns: 3;
    gap: 9px;
    background-color: white;
    margin-top: -85px;
    position: relative;
    padding: 9px;
    z-index: 100000000;
}

.masonryGrid > a > img {
    width: 100%;
}

@media (max-width: 576px) {
    .masonryGrid {
        columns: 3;
    }
}
