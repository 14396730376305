@import url('https://use.typekit.net/mho3ddh.css');

.container {
    max-width: 1540px;
    margin: 0 auto;
    background-color: white;
    position: relative;
    margin: -420px auto 0 auto;
    padding: 40px 60px 40px 60px;
}

.heroImage {
    background-image: url('../../assets/img/hero-image.png');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.info {
    display: flex;
    flex-wrap: wrap;
    gap: 90px;
}

@media (max-width: 768px) {
    .container {
        padding: 20px 20px 20px 20px;
    }
}
