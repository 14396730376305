.h1 {
    text-align: center;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin-bottom: -5px;
}
.h3 {
    text-align: center;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #4a5454;
    margin-top: 0px;
    font-size: 17px;
    margin-bottom: 47px;
}

.container {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
}

.img {
    position: relative;
    max-width: 480px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.arrowLeft {
    cursor: pointer;
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
    position: absolute;
    left: -43%;
    margin-top: -20px;
    margin-left: -20px;
    z-index: 999;
}

.arrowLeft:hover {
    opacity: 0.8;
}

.arrowRight {
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 144%;
    margin-top: -20px;
    margin-left: -20px;
    z-index: 999;
}

.arrowRight:hover {
    opacity: 0.8;
}

.closeImgContainer {
    max-width: 1600px;
    margin-top: 46px;
    display: flex;
    justify-content: end;
}

.closeImg {
    width: 15px;
}

.models {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 700;
    font-style: normal;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    margin-top: 28px;
}

.date {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    color: #7d878c;
    margin-top: 0px;
    margin-bottom: 29px;
}

.img > div {
    margin-left: auto;
    margin-right: auto;
}

.description {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 300;
    font-size: 17px;
    font-style: normal;
    max-width: 480px;
    margin-left: auto;
    color: #4a5454;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: auto;
    margin-left: auto;
}

.yt {
    height: 37px;
}

.socials {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-size: 17px;
    font-style: normal;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 30px;
}

.socialMedia {
    height: 40px;
    width: 40px;
    background-color: #8c989c;
    margin-bottom: 0px;
    border-radius: 100%;
    margin-right: 20px;
}

.socialMediaDORO {
    height: 40px;
    width: 40px;
    background-color: #8c989c;
    margin-bottom: 0px;
    border-radius: 100%;
}

.socialMediaContainer {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 46px;
    border-radius: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
}

.socialMediaContainerPhotographer {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 0px;
    border-radius: 100%;
    display: flex;
    gap: 30px;
}

.hr {
    max-width: 1085px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.info {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 7px;
}

.info > h1 {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-size: 17px;
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
}

.info > p {
    text-align: left;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    max-width: 1493px;
    color: #4a5454;
    margin-top: 0px;
}

.img2 {
    height: 70px !important;
    width: 70px !important;
    max-width: max-content !important;
}

.img3 {
    width: 70px !important;
    max-width: max-content !important;
}

.img2DORO {
    width: 160px !important;
    margin-top: 40px;
    max-width: max-content !important;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .h1 {
        display: none;
    }
    .arrowLeft {
        display: none;
    }
    .arrowRight {
        display: none;
    }

    .closeImgContainer {
        display: none;
    }

    .h3 {
        display: none;
    }

    .padding {
        padding: 20px;
    }

    .models {
        margin-top: 15px;
        margin-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .date {
        margin-bottom: 7px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .description {
        padding-left: 20px;
        padding-right: 20px;
    }

    .socials {
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .socialMediaContainer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .socialMediaContainerPhotographer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .info {
        gap: 0px;
    }

    .img {
        top: 0px;
    }
}

.info > h1 {
    text-decoration: none;
    color: black;
}

video {
    max-width: 100%;
    height: auto;
    display: block;
}
