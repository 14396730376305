.masonryGrid {
    columns: 4;
    gap: 1rem;
    background-color: white;
    position: relative;
    padding: 13px;
    z-index: 100000000;
}

.masonryGrid > li {
    margin-bottom: 14px;
}

@media (max-width: 576px) {
    .masonryGrid {
        columns: 2;
        margin-top: -32px;
    }
}

@media (max-width: 767px) {
    .masonryGrid {
        margin-top: -10px;
    }
}

@media (max-width: 489px) {
    .masonryGrid {
        margin-top: -32px;
    }
}

@media (max-width: 450px) {
    .masonryGrid {
        margin-top: -32px;
    }
}

@media (max-width: 437px) {
    .masonryGrid {
        margin-top: -32px;
    }
}

.searchImage {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.searchingMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdfdfc;
    width: 233px;
    margin-left: 17px;
    margin-bottom: 10px;
}

#search {
    width: 32vw;
    background-color: #fdfdfc;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}

#search:focus-visible {
    outline: none;
}

@media (max-width: 768px) {
    #search {
        width: 44vw;
    }
}
