.heading > h1 {
    font-size: 28px;
    margin-bottom: 40px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.paragraph > p {
    font-size: 20px;
    color: #5c6767;
    margin-bottom: 40px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.paragraph2 > h2 {
    font-size: 20px;
    margin-bottom: 90px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.searchImageInput {
    background-image: url('../../assets/img/small_arrow.png');
    background-color: red !important;
}

.heading2 > h2 {
    font-size: 20px;
    margin-top: 90px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 700;
    font-style: normal;
}

.paragraph3 {
    font-size: 20px;
    color: #5c6767;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.paragraph3 > p:first-child {
    margin-bottom: 0px;
}

.paragraph3 > p:last-child {
    margin-top: 0px;
}

.contactUs {
    color: black;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.paragraph4 {
    font-size: 20px;
    color: #5c6767;
    margin-top: 50px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.paragraph4 > p:first-child {
    margin-bottom: 0px;
}

.paragraph4 > p:last-child {
    margin-top: 0px;
}

.heading3 > h2 {
    font-size: 20px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 700;
    font-style: normal;
}

.paragraph5 {
    font-size: 20px;
    color: #5c6767;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

input {
    padding: 0px;
    height: 50px;
    border: 1px solid #a3afb5;
    background-color: #f0f3f5;
    padding-left: 20px;
    padding-right: 23px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.image {
    margin-left: auto;
    margin-right: auto;
    width: 85%;
}

.inputText {
    display: flex;
    margin-top: 50px;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
}

.searchingMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f3f5;
    padding-left: 12px;
    width: 233px;
    border: 1px solid #a3afb5;
    border-radius: 2px;
}

.searchImage {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

#search {
    width: 32vw;
    background-color: #f0f3f5;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}

#search:focus-visible {
    outline: none;
}
