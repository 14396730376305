.container {
    max-width: 1540px;
    margin: 0 auto;
    background-color: white;
    position: relative;
    margin: -420px auto 0 auto;
    padding: 40px 60px 40px 60px;
}

.heroImage {
    background-image: url('../../assets/img/quick-chat-main.png');
    height: 100vh;
    min-height: 900px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: relative;
}

.info {
    display: flex;
    flex-wrap: wrap;
    gap: 90px;
}

@media (max-width: 768px) {
    .container {
        padding: 20px 20px 20px 20px;
    }
}

.heading > h1 {
    font-size: 28px;
    margin-left: 69px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.arrow:hover {
    cursor: pointer;
}

.paragraph {
    font-size: 20px;
    color: #5c6767;
    margin-bottom: 20px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.column {
    display: flex;
    flex-direction: column;
    max-width: 570px;
}

.columnContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.column > img {
    margin-bottom: 21px;
}

.arrow {
    height: 46px;
    width: 50px;
}

.hr {
    width: 98%;
}

.other {
    font-size: 20px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.author {
    font-size: 20px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #a3a3a3;
    margin-bottom: 5px;
    margin-top: 0px;
}

@media (max-width: 768px) {
    .heading > h1 {
        margin-left: 0px;
    }
}
