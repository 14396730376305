.heading > h1 {
    font-size: 28px;
    margin-bottom: 20px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.secondaryHeading > p {
    font-size: 20px;
    color: #5c6767;
    margin-bottom: 20px;
}

.paragraph {
    font-size: 20px;
    color: #5c6767;
    margin-bottom: 80px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}
