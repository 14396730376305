.tilesVideo {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: absolute;
    right: 0px;
    bottom: 10px;
    max-width: 100%;
    padding: 8px;
    justify-content: flex-end;
    box-sizing: border-box;
}

.sliderContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    max-width: 405px;
    margin: 0 30px;
    height: 26vw;
    max-height: 90px;
    width: calc(100vw - 80px);
}

.sliderContainer>div {
    width: 100%;
}

.multipleTilesVideo {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0px;
    bottom: 55px;
    max-width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

@media (max-width: 501px) {

    .tilesVideo,
    .multipleTilesVideo {
        right: 0;
        bottom: 10px;
    }
}

.customControls {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
        background-color: white;
        color: #4a5454;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
    }
}

@media (min-width: 501px) and (max-width: 1023px) {
    .multipleTilesVideo {
        right: 1px;
        bottom: 10px;
    }
}

@media all and (min-width: 1024px) {
    .sliderContainer {
        position: absolute;
        bottom: 55px;
    }

    .multipleTilesVideo {
        bottom: 10px;
        justify-content: flex-end;
    }
}

.storeButton {
    position: relative;
    background-color: white;
    border-radius: 50%;
    font-size: 18px;
    height: 38px;
    width: 38px;
    bottom: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.storeButton:hover {
    cursor: pointer;
}

@media (max-width: 1023px) {
    .sliderContainer {
        position: absolute;
        right: 0;
        bottom: 24px;
    }

    .storeButton {
        height: 34px;
        width: 34px;
    }
}

.blobs-container {
    display: flex;
}

.blob {
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
    transform: scale(1);
    animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.pausePlay {
    width: 35px;
}

.defaultStyleTiles {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.isMobileCanvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
}

.isMobilePlayerWrapper {
    position: relative;
    width: 100%;
    // max-width: 480px;
    height: auto;
    overflow: hidden;
}

.isMobileContainer {
    display: flex;
    flex-direction: column;
}

.isDesktopContainer {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.isDesktopWrapper {
    display: flex;
    gap: 20px;
}

.isDesktopWrapper2 {
    display: flex;
    flex-direction: column;
}

.isDesktopPlayerWrapper {
    position: relative;
}

.isDesktopCanvas {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
}