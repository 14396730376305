.heroImage {
    background-image: url('../../assets/img/hero-image.png');
    height: 85vh;
    min-height: 850px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

button {
    background-color: #fff;
}

.heroText {
    text-align: left;
    position: absolute;
    left: 20%;
    bottom: 135px;
    transform: translate(-50%, 0%);
    color: rgb(0, 0, 0);
    background-color: white;
    max-width: 95vw;
}

.heroText > div > h1 {
    padding-bottom: 13px;
    margin-bottom: -1px;
    padding-left: 18px;
    padding-top: 11px;
    font-size: 50px;
    margin-top: 0px;
    max-width: 356px;
    font-family: 'source-sans-pro', sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
}

.heroText > div > p {
    padding-left: 18px;
    margin-bottom: -1px;
    padding-bottom: 10px;
    margin-top: 0px;
    max-width: 356px;
    padding-right: 10%;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.heroText > .button {
    margin-bottom: 6px;
    padding-bottom: 12px;
}

.heroText > .button > button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px 18px;
    color: black;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 500;
    font-style: normal;
}

a {
    color: black;
}

.heroText > div:last-child > p {
    padding-top: 9px;
    padding-bottom: 16px;
    font-family: 'source-sans-pro', sans-serif;
    font-style: normal;
}

@media (max-width: 1024px) {
    .heroText {
        left: 25%;
    }
}

.heroSectionWrapper {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1585px;
    flex-direction: row-reverse;
    padding-top: 1rem;
    padding-right: 20px;
    padding-left: 20px;
    align-items: end;
    height: 100%;
    padding-bottom: 120px;
}

@media (max-width: 768px) {
    .heroImage {
        min-height: none;
        background-image: url('../../assets/img/Riva.jpg');
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
    }

    .heroText > .button {
        padding-bottom: 0px;
    }

    .heroText > .button > button > u > a {
        font-size: 12px;
    }

    .heroSectionWrapper {
        flex-direction: column;
        padding-right: 20px;
        padding-left: 20px;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 40px;
        height: 776px;
    }

    .heroText {
        margin-top: 0px;
        width: 335px;
        left: auto;
        right: 30px;
        bottom: 15%;
        transform: translate(0, 0);
        font-weight: 900;
    }

    .heroText > div > h1 {
        font-size: 31px;
        padding-right: 90px;
        line-height: 31px;
    }

    .heroText > div > p {
        font-size: 13px;
    }

    .heroText > div:last-child {
        display: none;
    }

    .hrHero {
        display: none;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .heroSectionWrapper {
        align-items: normal;
    }
}

@media (min-width: 1px) and (max-width: 480px) {
    .heroText {
        width: 100%;
        max-width: none;
    }
}

// @media(max-width: 767px) {
//     .heroText {
//       margin-top: 0px;
//     }
//     .heroText {
//       width: auto;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//     .heroText > div > h1 {
//       font-size: 35px;
//     }
// }

// @media(max-width: 576px) {
//     .heroText {
//       margin-top: 0px;
//     }
//     .heroText {
//       width: auto;
//       left: 26%;
//       transform: translate(-10%, -47%);
//     }
//     .heroText > div > h1 {
//       font-size: 35px;
//     }
// }

// @media(max-width: 489px) {
//     .heroText {
//       width: auto;
//       left: 26%;
//       transform: translate(-13%, -47%);
//     }
//     .heroText > div > h1 {
//       font-size: 35px;
//     }
// }

// @media(max-width: 450px) {
//     .heroText {
//         width: auto;
//         left: 26%;
//         transform: translate(-11%, -47%);
//     }
//     .heroText > div > h1 {
//         font-size: 35px;
//     }
// }

// @media(max-width: 437px) {
//     .heroText {
//         width: auto;
//         left: 26%;
//         transform: translate(-16%, -47%);
//     }
//     .heroText > div > h1 {
//         font-size: 35px;
//     }
// }

// @media(max-width: 576px) {
//   .heroText {
//     margin-top: 0px;
//   }
//   .heroText {
//     width: auto;
//     left: 26%;
//     transform: translate(-10%, -47%);
//   }
//   .heroText > div > h1 {
//     font-size: 35px;
//   }
// }

// @media(max-width: 489px) {
//   .heroText {
//     width: auto;
//     left: 26%;
//     transform: translate(-13%, -47%);
//   }
//   .heroText > div > h1 {
//     font-size: 35px;
//   }
// }

// @media(max-width: 450px) {
//   .heroText {
//       width: auto;
//       left: 26%;
//       transform: translate(-11%, -47%);
//   }
//   .heroText > div > h1 {
//       font-size: 35px;
//   }
// }

// @media(max-width: 768px) {
//   .heroText > div:last-child {
//       display: none;
//   }

//   .heroText {
//     margin-top: 24vh;
//   }
// }

.heroText {
    text-align: left;
    color: rgb(0, 0, 0);
    background-color: white;
    max-width: 95vw;
    position: unset;
    transform: none;
    max-height: 533px;
}

.hrHero {
    height: 8px;
    color: black;
    width: 100;
    background: #343434;
    border: none;
}
