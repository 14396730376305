.tile {
    position: absolute;
    right: 19px;
    bottom: 19px;
    width: 26vw;
    max-width: 122px;
    max-height: 122px;
    height: 26vw;
    z-index: 10000;
    background-color: #fff;
}

.href {
    display: flex;
    flex-direction: column;
    height: 100%;

    .minatureContainer {
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        overflow: hidden;

        .minature {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .text {
        padding: 3px;
        text-align: center;
        flex-shrink: 0;

        h2 {
            margin: 0;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        p {
            margin: 0;
            margin-top: -5px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
}
