.author {
    text-align: right;
    color: black;
    background-color: white;
    text-align: left;
    width: 219px;
    padding: 1px 27px;
    font-size: 15px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 37rem;
    max-height: 140rem;
}

.datePlace {
    color: #8c989c;
    margin: 0px;
    margin-bottom: 10px;
}

.nameContainer {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    border-top: 1px solid #8c989c;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.photo {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: grey;
}

@media (max-width: 768px) {
    .author {
        margin-top: 19rem;
        font-weight: 600;
        position: unset;
        z-index: 100;
        padding: 1px 11px;
        font-size: 12px;
        padding-top: 4px;
        padding-bottom: 8px;
        margin-left: auto;
        margin-bottom: 14px;
        width: 144px;
    }

    .photo {
        display: none;
    }

    .authorName {
        display: none;
    }

    .nameContainer {
        display: none;
    }

    .author > p {
        margin-bottom: -1px;
        margin-top: 6px;
    }
}

@media (min-width: 480px) {
    .author {
        margin-left: 0px;
    }
}
