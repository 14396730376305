.customFileInput {
    color: transparent;
    margin-left: 18px;
    background: none;
    border: none;
    height: 60px;
    margin-left: auto !important;
    margin-right: auto !important;
}
.customFileInput::-webkit-file-upload-button {
    visibility: hidden;
}
.customFileInput::before {
    content: 'Upload image';
    color: rgb(255, 255, 255);
    display: inline-block;
    background: #8c989c;
    border-radius: 3px;
    padding: 15px 18px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    display: flex;
    justify-content: center;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}
.customFileInput:hover::before {
    border-color: black;
}
.customFileInput:active {
    outline: 0;
}
.customFileInput:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.fileButton {
    background: #8c989c;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 14px 18px;
    cursor: pointer;
    transition: 0.5s;
    width: 176px;
    margin-left: 71px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.fileButton:hover {
    background-color: rgb(255, 255, 255);
    color: #8c989c;
    transition: 0.5s;
}

.submitForm {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.submitForm > input {
    padding: 8px 15px;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 10px;
}

.submitForm > select {
    padding: 8px 15px;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 10px;
    background: #f7f7f7;
    height: 50px;
}

.preview {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    margin-bottom: 40px;
    font-family: 'source-sans-pro', sans-serif;
}

form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 700px !important;
}

.h1 {
    text-align: center;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 50px;
}
